<template>
  <div>
    <p class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4">
      {{ risque.name }}
    </p>
    <p
      v-for="(definition, index) in risque.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
      <br />
      {{ risques.definitions_risques.RADON.definitionbis }}
    </p>
    <div v-for="(sub, idx) in risque.sub_title" :key="'sub' + idx" class="py-6">
      <p class="text-promy-gray-600 font-arial font-bold sm:text-sm py-4">
        {{ sub.descr }}
      </p>
      <div class="w-full flex md:flex-col justify-start items-start">
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            :id_map="`map_radon_${idx}`"
            :layer="sub.layer"
            :opacity="0.8"
            ref="map"
            v-if="active"
          />
        </div>
        <img
          :src="`https://mapsref.brgm.fr/wxs/georisques/risques?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=${sub.layer}`"
          class="ml-6 md:ml-0 md:mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Map from './map.vue'
import risques from '@/mixins/risques'
import helpers from '@/mixins/helpers'

export default {
  mixins: [risques, helpers],

  props: {
    risque: {
      type: Object,
      require: true,
    },
    active: {
      type: Boolean,
      require: true,
    },
  },

  components: {
    Map,
  },

  data() {
    return {
      alea_values: ['Faible', 'Moyen', 'Fort'],
    }
  },

  created() {
    this.risque.sub_title.forEach((sub) => {
      if (sub.key === 'alea') {
        try {
          sub.value =
            this.alea_values[this.risques.radon[0].classePotentiel - 1]
        } catch (e) {
          sub.value = 'Faible'
        }
      }
    })
  },

  computed: {
    ...mapGetters({
      risques: 'address/risques',
      long: 'address/long',
      lat: 'address/lat',
    }),
  },
}
</script>

<style></style>
